#study-guide {
  height: 100%;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

#study-guide #studyguide-menu {
  height: 50px;
  padding: 20px 20px 40px 20px;
  width: 100%;
  background-color:#eeeeee;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 2px 2px -2px rgba(0,0,0,.2);
  box-sizing: border-box;
}

#study-guide .menu-heading {
  width: 20%;
  font-size: 1.1em;
  font-weight: bold;
}

#study-guide .menu-items {
  width: 80%;
  display: flex;
  font-size: 1.1em;
  justify-content: flex-end;
}

#study-guide .menu-item {
  cursor: pointer;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
}

#study-guide #study-content {
  box-sizing: border-box;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: calc(100% - 80px); /* minus height of menu or portion at bottom won't show */
  overflow: scroll;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-top: 5px; /* compensate for menu shadow */
}

#study-guide #study-content-no-menu {
  box-sizing: border-box;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-top: 5px; /* compensate for menu shadow */
}

#study-guide .section {
  width: 100%;
  padding: 20px 0;
}

#study-guide .dim {
  opacity: 0.2;
}

#study-guide .section .section-heading {
  background-color: #494949;
  color: #f3f3f3;
  padding: 10px 10px;
  font-weight: bold;
  font-size: 1.2em;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#study-guide .section .section-description {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0px 10px 5px;
  background-color: #494949;
  color: #f3f3f3;
  font-size: 80%;
}

#study-guide .sub-section .title {
  padding: 20px 0;
  font-weight: bold;
  font-size: 1.5em;
}

#study-guide .section .section-content .title {
  font-weight: bold;
  font-size: 1.5em;
}

#study-guide .author {
  font-weight: bold;
}

#study-guide .section .section-content .date {
  font-size: 0.8em;
}

#study-guide .section .section-content .desc {
  font-size: 0.8em;

    /* below takes care of long URL that's unable to wrap */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

#study-guide .version-name {
  font-size: 1.1m;
  font-weight: bold;
  white-space: nowrap;
}

#study-guide #sermons .section-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: (400px, auto);
}

#study-guide .sermon-block {
  background-color: rgb(247, 245, 243);
  width: cal(100% - 20px);
  padding: 10px;
  border: solid 5px white;
  border-radius: 10px;
}

#study-guide .words-table {
  display: flex;
  flex-wrap: wrap;
}

#study-guide .words-table .word-cell {
  margin: 15px;
}

#study-guide .translit {
  font-style: italic;
}

#study-guide .word-link {
  border-bottom: dotted 2px rgb(42, 71, 128);
  color: rgb(42, 71, 128);
  cursor: pointer;
}

#study-guide .greek {
  font-weight: 200;
  font-size: 140%;
}

#study-guide .paragraph-block {
  background-color: rgb(247, 245, 243);
  padding: 10px 10px;
  border: solid 2px white;
  border-radius: 10px;
}

#study-guide .paragraph-block-specific {
  background-color: rgb(247, 245, 243);
  padding: 10px 10px;
  border: dashed 2px #616161;
  border-radius: 10px;
}

#study-guide .paragraph {
  /* below takes care of long URL that's unable to wrap */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

#study-guide .cr-verseKey-col {
  width: 15%;
  padding: 10px;
}

#study-guide .cr-verse-col {
  width: 55%;
  padding: 10px;
}

#study-guide .cr-verse-col p {
  margin: 0;
}

#study-guide .cr-verse-col .cr-verse-lang2 {
  font-size: 80%;
  line-height: 1.2em;
}

#study-guide .cross-ref-words-col {
  width: 30%;
  padding: 10px;
}

#study-guide .video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;
}

#study-guide .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#study-guide #hymns {
  display: none;
}

#study-guide table td {
  vertical-align: top;
  padding-right: 15px;
}

#study-guide .hidden {
  display: none;
}

#study-guide #word-lookup {
  position: fixed;
  top: 200px;
  bottom: 100px;
  width: 400px;
  right: 0px;
  overflow-y: scroll;
  padding: 2px 20px;
  background-color: rgba(78, 78, 78, 0.9);
  color: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#study-guide #word-lookup a, #word-lookup a:visited {
   color: #ddd; 
   text-decoration: underline;
}

#study-guide #word-lookup h3 {
  clear: both;
  border-bottom: 1px dotted;
}

#study-guide #word-lookup td {
  color: #fff;
}

#study-guide .disable-link {
  /* pointer-events: none; */
  cursor: default;
}

@media screen and (max-width: 1100px) {
  #study-guide #sermons .section-content {
    grid-template-columns: repeat(1, 1fr);
  }

  #study-guide .menu-heading {
    font-size: 1em;
  }
  
  #study-guide .menu-items {
    font-size: 1em;
  }
}



@media screen and (max-width: 500px) {

  #study-guide .menu-heading {
    font-size: 0.7em;
  }
  
  #study-guide .menu-items {
    font-size: 0.7em;
  }

  #study-guide #word-lookup {
    width: 80%;
  }
}