#book-menu {
  position: relative;
  width: 100%;
  background-color: #494949;;
}

#book-menu header {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

#book-menu #book-selector {
  position: relative;
  padding: 10px 10px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

#book-menu #search {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#book-menu .search-box {
  width: 200px;
  height: 30px;
  box-sizing: border-box;
}

#book-menu .search-button {
  height: 30px;
}

#book-menu #user {
  width: 5%;
}

#book-menu .prev {
  padding: 0 10px;
}

#book-menu .prev-disabled {
  cursor: default;
  padding: 0 10px;
}

#book-menu .book-nav {
  align-items: center;
}

#book-menu .next {
  padding: 0 10px;
}

#book-menu .next-disabled {
  cursor: default;
  padding: 0 10px;
}

#book-menu .book-dropdown-button {
  font-size: 1em;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #949494;
}

#book-menu .hide {
  display: none;
}

#book-menu .book-dropdown {
  position: absolute;
  font-size: 1em;
  width: 200px;
  height: 500px;
  background-color: #ffffff;
  overflow-y: scroll;
  left: calc(50% - 200px);
  top: 70px;;
  z-index: 10;
  border: solid #949494 1px;
  border-radius: 4px;
}

#book-menu .book-list {
  list-style-type: none;
  text-align: left;
  cursor: pointer;
  padding-left: 0;
}

#book-menu .book-list-item {
  width: calc(100% - 5px);
  padding-left: 5px;
}

#book-menu .highlight-current {
  background-color: rgb(42, 71, 128, 0.8);
  color: white;
}

#book-menu .book-list-item:hover {
  background-color: rgb(42, 71, 128);
  color: white;
}

#book-menu .chapter-dropdown {
  position: absolute;
  font-size: 1em;
  width: 200px;
  height: 500px;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  left: calc(50%);
  top: 70px;;
  z-index: 10;
  border: solid #949494 1px;
  border-radius: 4px;
}

#book-menu .chapter-list {
  list-style-type: none;
  text-align: left;
  cursor: pointer;
  padding-left: 0;
  width: 100%;
}

#book-menu .chapter-list-item {
  width: calc(99% - 10px);
  padding-left: 10px;
}

#book-menu .chapter-list-item:hover {
  background-color: rgb(42, 71, 128);
  color: white;
}

#book-menu .triangle {
  border: solid #616060 2px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin: 5px 10px;
}

#book-menu .triangle-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#book-menu .triangle-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  padding: 8px;
  border-color: #ffffff;
}

#book-menu .triangle-prev-disabled {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  padding: 8px;
  border-color: #616161;
}

#book-menu .triangle-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  padding: 8px;
  border-color: #ffffff;
}

#book-menu .triangle-next-disabled {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  padding: 8px;
  border-color: #616161;
}

#book-menu .disable-link {
  /* pointer-events: none; */
  cursor: default;
}

@media screen and (min-width:501) and (max-width: 700px) {

  #book-menu #book-selector {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 500px) {

  #book-menu header {
    display: none;
  }

  #book-menu #book-selector {
    justify-content: center;
  }

  #book-menu .book-dropdown {
    width: 150px;
    left: calc(50% - 150px);
  }

  #book-menu .chapter-dropdown {
    width: 150px;
  }
}