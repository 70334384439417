.bible-app {
  font-family: ArialMT, "Helvetica Neue", Arial, "Liberation Sans", FreeSans, sans-serif;
  font-size: 16px;
  color: #616161;
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.header {
  height: 70px;
}

.body {
  display: flex;
  flex-direction: row;
  height: calc(100% - 70px - 25px);
}

.body-left {
  width: 35%;
  height: 100%;
}

.body-right {
  width: 65%;
  height: 100%;
}

.footer {
  height: 25px;
}

@media screen and (max-width: 1000px) {
  .body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px - 25px);
  }

  .body-left {
    width: 100%;
    height: 30%;
  }
  
  .body-right {
    width: 100%;
    height: 70%;
  }
}

